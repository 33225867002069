<template>
    <div class="mypage_item_history_wrap">
        <!--        공모전 심사위원 레이아웃-->
        <table class="my_history_table" width="100%"  border="0" cellspacing="0" cellpadding="0"
               style = "table-layout: auto; width: 1200px; table-layout: fixed;">
            <colgroup>
                <col width="90px" />
                <col width="135px" />
                <col width="190px" />
                <col width="130px" />
                <col width="105px" />
                <col width="130px" />
                <col width="155px" />
                <col width="140px" />
                <col width="120px" />
            </colgroup>
            <tr class="title_row">
                <th colspan="1">No</th>
                <th colspan="1">{{$t('image')}}</th>
                <th colspan="1">{{$t('subject')}}</th>
                <th colspan="1">{{$t('t_prize')}}</th>
                <th colspan="1">{{$t('organizer')}}</th>
                <th colspan="1">{{$t('e_period')}}</th>
                <th colspan="1">{{$t('d_day')}}</th>
                <th colspan="1">{{$t('status')}}</th>
                <th colspan="1">{{$t('enroll')}}{{$t('dateTime')}}</th>
            </tr>
            <tr v-for="(competition, index) in competitionData" :key="`competition-judge-${competition.c_idx}`"
                @click="movePage(`/competition/${competition.c_uuid}`)" class="competition_list">
                <td>{{tableIdx(index)}}</td>  <!-- No -->
                <td><img :src="returnImage(competition.Poster,78,49)" width="78" height="49"/></td>
                <td class="t_subject">{{competition.c_title}}</td>

                <td>{{returnCommas(competition.c_prize)}}</td> <!-- 총상금 -->

                <td>{{competition.mb_nick}}</td> <!-- 주최자 -->

                <td v-html="returnApplyDate(competition)"></td>  <!-- 응모기간 -->

                <td>{{ returnDateTime(competition.c_release_time) }}</td> <!-- 발표일 -->

                <td>{{ returnStatus(competition.c_status) }}        </td>

                <td>{{ returnDateTime(competition.created_at) }}</td>  <!-- 등록일시 -->
            </tr>
        </table>
    </div>
</template>

<script>
import util from "@/mixins/util";
import alertMixins from "@/mixins/alertMixins";
import imageOption from "@/mixins/imageOption";
import imageResizeMixins from "@/mixins/imageResizeMixins";
export default {
    name: "CompetitionListJudgeLayout",
    mixins: [alertMixins, imageOption,imageResizeMixins],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        competitionData: {
            type: Object,
            default: () => {
                return {}
            }
        },
        paginationData: {default: {page: 1, size: 10, total: 0}},
    },
    data() {
        return {
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        movePage(url) {
            this.$router.push(url)
        },
        returnImage(image, width = 32, height = 32) {
            if (!util.isEmpty(image)) {
                return `${image[0].org_url}?${this.setImageOptions(width, height, this.returnExtension(image[0].org_url))}`
            }
            return ''
        },
        returnCommas(x=0) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        returnApplyDate(data) {
            let s_time = this.$moment(data.c_start_time).format('YYYY-MM-DD')
            let e_time = this.$moment(data.c_end_time).format('YYYY-MM-DD')
            return `${s_time} ~ <br/> ${e_time}`
        },
        returnDateTime(time) {
            let r_date = this.$moment(time).format('YYYY-MM-DD HH:mm')
            return r_date;
        },
        returnStatus(status) {
            switch (status) {
                case 0 :{
                    return this.$t('scheduled_to_proceed')
                }
                case 1 :{
                    return this.$t('in_process2')
                }
                case 2 :{
                    return this.$t('under_review')
                }
                case 3 :{
                    return this.$t('p_completed')
                }
                default :{
                    return ''
                }
            }
        },
        tableIdx(index) {
            return this.paginationData.total - index - (this.paginationData.page - 1) * this.paginationData.size;
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
